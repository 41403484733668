import { BASE_URL } from "../utils/constants";
import axiosClient from "./apiClient";

const usersApi = {
  signIn: async (params) => {
    try {
      const url = `${BASE_URL}/users/login`;
      const response = await axiosClient.post(url, params);
      return response.data;
    } catch (error) {
      throw error.data;
    }
  },
  signUp: async (params) => {
    try {
      const url = `${BASE_URL}/users`;
      const response = await axiosClient.post(url, params);
      return response;
    } catch (error) {
      if (Array.isArray(error.data.errors) && error.data.errors.length > 0) {
        const errors = error.data.errors.reduce(
          (obj, item) => Object.assign(obj, { [item.meta.field]: item.detail }), {});
        throw errors;
      }
      throw error.data;
    }
  },
  signOut: async () => {
    try {
      const url = `${BASE_URL}/users/sign_out`;
      await axiosClient.delete(url);
      return true;
    } catch (error) {
      throw error.data;
    }
  },
  update: async (id, params) => {
    try {
      const url = `${BASE_URL}/users/${id}`;
      await axiosClient.put(url, params);
    } catch (error) {
      throw error.data;
    }
  },
  changePassword: async (params) => {
    try {
      const url = `${BASE_URL}/users`;
      await axiosClient.put(url, params);
    } catch (error) {
      if (Array.isArray(error.data.errors) && error.data.errors.length > 0) {
        const errors = error.data.errors.reduce(
          (obj, item) => Object.assign(obj, { [item.meta.field]: item.detail }), {});
        throw errors;
      }
      throw error.data;
    }
  },
  changeNewPassword: async (params) => {
    try {
      const url = `${BASE_URL}/users/password`;
      await axiosClient.put(url, params);
    } catch (error) {
      if (Array.isArray(error.data.errors) && error.data.errors.length > 0) {
        const errors = error.data.errors.reduce(
          (obj, item) => Object.assign(obj, { [item.meta.field]: item.detail }), {});
        throw errors;
      }
      throw error.data;
    }
  },
  updateAvatar: async (id, params) => {
    try {
      const url = `${BASE_URL}/users/${id}/update_avatar`;
      const response = await axiosClient.post(url, params, {
        headers: {
        }
      });
      return response.data;
    } catch (error) {
      throw error.data;
    }
  },
  getStudents: async () => {
    try {
      const url = `${BASE_URL}/users/student`;
      const response = await axiosClient.get(url);
      return response.data;
    } catch (error) {
      throw error.data;
    }
  },
  getInstructors: async () => {
    try {
      const url = `${BASE_URL}/users/instructor`;
      const response = await axiosClient.get(url);
      return response.data;
    } catch (error) {
      throw error.data;
    }
  },
  resetPassword: async (params) => {
    try {
      const url = `${BASE_URL}/users/password`;
      const response = await axiosClient.post(url, params);
      return response;
    } catch (error) {
      if (Array.isArray(error.data.errors) && error.data.errors.length > 0) {
        const errors = error.data.errors.reduce(
          (obj, item) => Object.assign(obj, { [item.meta.field]: item.detail }), {});
        throw errors;
      }
      if (error.data.errors) {
        throw error.data.errors;
      }
      throw error.data;
    }
  },
  updateUserSetting: async (id, params) => {
    try {
      const url = `${BASE_URL}/users/${id}/update_user_setting`;
      const response = await axiosClient.patch(url, params);
      return response.data;
    } catch (error) {
      throw error.data;
    }
  },
  fetchUsers: async () => {
    try {
      const url = `${BASE_URL}/users/fetch_users`;
      const response = await axiosClient.get(url);
      return response.data;
    } catch (error) {
      throw error.data;
    }
  },
};

export default usersApi;
