import React, { useState } from "react";
import { Modal, Backdrop, Fade, Button, TextField, Box, Typography } from "@material-ui/core";
import axios from "axios";

const FreeTrialModal = ({ isOpen, onClose }) => {
  const [formData, setFormData] = useState({ name: "", email: "" });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      await axios.post("/api/free_trial_request", formData); // Make the API call to Rails
      alert("Request sent! We'll get back to you soon.");
      onClose(); // Close the modal
    } catch (error) {
      alert("There was an error sending the request.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isOpen}>
        <Box
          style={{
            backgroundColor: "#fff",
            padding: 20,
            borderRadius: 8,
            width: 400,
            margin: "100px auto",
            textAlign: "center",
          }}
        >
          <Typography variant="h6">Request Free Trial</Typography>
          <Typography variant="body1" style={{ marginTop: 16, marginBottom: 16 }}>
            To ensure a smooth onboarding experience for your studio, we need to set up your account and integrate your studio into our platform. Please fill out the form below, and we'll get back to you to complete the process.
          </Typography>
          <TextField
            label="Name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Email"
            name="email"
            type="email"
            value={formData.email}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={isSubmitting}
            fullWidth
            style={{ marginTop: 20 }}
          >
            {isSubmitting ? "Submitting..." : "Submit"}
          </Button>
        </Box>
      </Fade>
    </Modal>
  );
};

export default FreeTrialModal;
