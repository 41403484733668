import React, { useState, useEffect } from "react";
import {
  Backdrop,
  Box,
  Fade,
  Modal,
  withStyles,
  createGenerateClassName,
  createTheme,
  StylesProvider,
  ThemeProvider,
  Typography,
  Button,
  FormControl,
  CircularProgress,
  FormControlLabel,
  Switch,
  Divider,
  FormLabel,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import imageSuccess from "../../../assets/images/sign-up-success.svg";
import usersApi from "../../../apis/usersApi";
import Select, { components } from 'react-select';
import _ from 'lodash';

const generateClassName = createGenerateClassName({
  productionPrefix: "c",
  disableGlobal: true,
  seed: "change-password-modal",
});

const theme = createTheme({
  typography: {
    fontFamily: "'Varela Round', sans-serif",
  },
  overrides: {
    MuiFormLabel: {
      root: {
        "&$focused": {
          color: "#333333",
        },
      },

      focused: {
        color: "#333333",
      },
    },
    MuiInput: {
      underline: {
        "&::after": {
          borderBottom: "2px solid #333333",
        },
      },
    },
  },
});

const ModalBox = withStyles((theme) => ({
  root: {
    backgroundColor: "#ffffff",
    padding: 32,
    borderRadius: 16,
    position: "relative",
    maxWidth: 500,
    "&:focus-visible": {
      outline: "none",
    },
    [theme.breakpoints.down("sm")]: {
      padding: 16,
      margin: 0,
      maxWidth: "100%",
      marginTop: "auto",
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
  },
}))(Box);

const Close = withStyles(() => ({
  root: {
    position: "absolute",
    right: 12,
    top: 12,
    cursor: "pointer",
  },
}))(Box);

const ModalHead = withStyles((theme) => ({
  root: {
    fontSize: 20,
    lineHeight: "28px",
    fontWeight: 500,
    margin: 0,
    marginBottom: 32,
    textTransform: "uppercase",
    [theme.breakpoints.down("sm")]: {
      marginTop: 16,
    },
  },
}))(Typography);

const SaveSyncButton = withStyles((theme) => ({
  root: {
    color: "#ffffff",
    backgroundColor: "#000000",
    "&:hover": {
      backgroundColor: "#000000",
    },
    height: 50,
    borderRadius: "140px",
    marginTop: 24,
  },
}))(Button);

const SuccessText = withStyles((theme) => ({
  root: {
    fontSize: 20,
    fontWeight: 400,
    margin: 0,
    textTransform: "uppercase",
    marginBottom: 8,
  },
}))(Typography);

const ThanksText = withStyles((theme) => ({
  root: {
    fontSize: 14,
    fontWeight: 400,
    margin: 0,
    marginBottom: 16,
    color: "#4F4F4F",
  },
}))(Typography);

const ImageSuccess = withStyles(() => ({
  root: {
    textAlign: "center",
    marginBottom: 30,
    minHeight: 160,
  },
}))(Box);

const HorizontalRow = withStyles(() => ({
  root: {
    width: "100%",
    margin: "16.5px 0",
    border: "1px solid #E0E0E0",
  },
}))(Divider);

const CalendarSyncModal = ({ currentUser, isOpen, onClose }) => {
  const [formData, setFormData] = useState({
    syncData: currentUser.user_setting.sync_data,
    showEventDetail: currentUser.user_setting.show_title_detail,
  });
  const [isSaveCalendarSyncSuccess, setIsSaveCalendarSyncSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isReload, setReload] = useState(false);
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const { share_calendar_with_others: oldUsers } = currentUser
  const { syncData, showEventDetail } = formData;
  
  const handleSwitchChange = (prop) => (event) => {
    setReload(!isReload);
    setFormData({ ...formData, [prop]: event.target.checked });
  };

  const onSaveSyncHandler = async () => {
    try {      
      setIsLoading(true);
      const data = {
        user_setting: {
          sync_data: syncData,
          show_title_detail: showEventDetail,
          shared_users: getSelectedOptions()
        },
      };
      await usersApi.updateUserSetting(currentUser.id, data);
      setIsSaveCalendarSyncSuccess(true);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const getSelectedOptions = () => {
    if(selectedUsers.length < 1) { return []; }

    return _.map(selectedUsers, 'value')
  }

  const fetchShowEventDetail = () => {
    if(!syncData) { return false }

    return showEventDetail
  }

  const Option = (props) => {
    return (
      <components.Option {...props}>
        <div className="left">{props.isSelected ? "✔" : ""}</div>
        <div className="right">
          <strong className="title">{props.data.label}</strong>
          <div>{props.data.description}</div>
        </div>
      </components.Option>
    );
  };
  
  const fetchUsers = async () => {
    const users = await usersApi.fetchUsers();
    const oldUsersIds = _.map(oldUsers, 'id')
    setUsers(users.map((user) => {
      const option = { value: user.id, label: user.attributes.full_name, description: user.attributes.email }
      if(oldUsersIds.includes(user.id)){
        // setSelectedUsers(option)
        setSelectedUsers(selectedUsers => [...selectedUsers, option]);
      }
      return option
    }))
  }

  useEffect(() => {
    fetchUsers();
  }, [])

  const onChange = (item) => {
    setSelectedUsers(item.map(user =>(
      { value: user.value, label: user.label, description: user.description }
    )));
  }

  return (
    <StylesProvider generateClassName={generateClassName}>
      <ThemeProvider theme={theme}>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={isOpen}
          onClose={onClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Fade in={isOpen}>
            <ModalBox>
              <Close onClick={onClose}>
                <CloseIcon />
              </Close>
              <ModalHead>Calendar Sync</ModalHead>
              {!isSaveCalendarSyncSuccess ? (
                <>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={syncData}
                        onChange={handleSwitchChange("syncData")}
                        name="syncData"
                        color="primary"
                      />
                    }
                    label="Sync data"
                    labelPlacement="start"
                    style={{
                      justifyContent: "space-between",
                      width: "100%",
                      margin: "0",
                    }}
                  ></FormControlLabel>
                  <HorizontalRow />
                  <FormControlLabel
                    control={
                      <Switch
                        disabled={!syncData}
                        checked={fetchShowEventDetail()}
                        onChange={handleSwitchChange("showEventDetail")}
                        name="showEventDetail"
                        color="primary"
                      />
                    }
                    label="Show event detail"
                    labelPlacement="start"
                    style={{
                      justifyContent: "space-between",
                      width: "100%",
                      margin: "0",
                    }}
                  ></FormControlLabel>
                  <FormLabel style={{ marginBottom: "40px", fontSize: 14 }}>
                    Hint: When this setting is OFF, your events will only show as "Not Available"
                  </FormLabel>

                  <HorizontalRow />

                  <Select
                    value={selectedUsers}
                    components={{ Option }}
                    maxMenuHeight={200}
                    placeholder={"Share with other users ..."}
                    onChange={(item) => onChange(item)}
                    options={users}
                    isDisabled={!syncData}
                    isMulti
                  />
      
                  <FormControl fullWidth>
                    <SaveSyncButton
                      variant="contained"
                      color="primary"
                      onClick={onSaveSyncHandler}
                    >
                      {!isLoading ? (
                        "Save"
                      ) : (
                        <CircularProgress
                          disableShrink
                          style={{ color: "#fff" }}
                        />
                      )}
                    </SaveSyncButton>
                  </FormControl>
                </>
              ) : (
                <>
                  <SuccessText>Success!</SuccessText>
                  <ThanksText>
                    Thanks, your setting has been successfully updated.
                  </ThanksText>
                  <ImageSuccess>
                    <img src={imageSuccess} alt="" />
                  </ImageSuccess>
                  <FormControl fullWidth>
                    <SaveSyncButton
                      variant="contained"
                      color="primary"
                      onClick={window.location.reload()}
                    >
                      OK
                    </SaveSyncButton>
                  </FormControl>
                </>
              )}
            </ModalBox>
          </Fade>
        </Modal>
      </ThemeProvider>
    </StylesProvider>
  );
};

export default CalendarSyncModal;
