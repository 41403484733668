export const ROUTE = {
  HOME: "/",
  DASHBOARD: "/pages/dashboard",
};

export const MODAL_TYPES = {
  SIGN_IN: "SIGN_IN",
  SIGN_UP: "SIGN_UP",
  RESET_PASSWORD: "RESET_PASSWORD",
}

export const ALERT_TYPES = {
  SUCCESS: "success",
  ERROR: "error",
}

export const BASE_URL = window.location.origin;

export const API_PREFIX = "api";

export const VERSION = "v1";

export const LESSON_STATUS = {
  UP_COMING: "scheduled",
  COMPLETED: "completed",
  PROPOSED: "scheduling",
};

export const USER_ROLES = {
  STUDENT: "student",
  INSTRUCTOR: "instructor",
  STUDIO: "owner",
}

export const STUDENT_TABS = {
  HOME: "HOME",
  MY_LESSONS: "MY_LESSONS",
  PROPOSED: "PROPOSED",
}

export const PROPOSED_TIME_STATUS = {
  PENDING: "pending",
  REJECTED: "rejected",
  CONFIRMED: "confirmed",
}

export const DATE_FORMAT_DEFAULT = "yyyy/MM/dd";

export const TIME_FORMAT = "hh:mm aa";

export const LESSON_NOTE_DATE_TIME = "yyyy/MM/dd hh:mm aa";

export const CALENDAR_LESSON_DATE_TIME = "yyyy-MM-dd HH:mm";

export const LESSON_DATE_TIME = "yyyy/MM/dd hh:mm aa";

export const BULLETIN_LIST_DATE = "MM/dd/yyyy";

export const FOOTER_COLLAPSE_TYPES = {
  DANCERS: "DANCERS",
  INSTRUCTORS: "INSTRUCTORS",
  STUDIO_OWNERS: "STUDIO_OWNERS",
  EVENTS: "EVENTS",
  CONTACT: "CONTACT",
}

export const LESSON_DESIRED_COUNTER_TYPES = {
  ADD: "ADD",
  MINUS: "MINUS",
}

export const PROPOSAL_TIME_OPTION_TYPES = {
  RECURRING: "recurring",
  "1X": "1x",
  "2X": "2x",
  "3X": "3x",
  FORCE_CONFIRMATION: "force_confirmation",
}

export const PROPOSAL_TYPES = {
  STUDENT_TO_INSTRUCTOR: "from_student_to_instructor",
  INSTRUCTOR_TO_STUDENT: "from_instructor_to_student",
}

export const PROPOSAL_TIME_DURATION_TYPES = {
  "0.75": "1X",
  "1.5": "2X",
  "2.25": "3X",
}

export const PROPOSAL_TIME_ACTION_TYPES = {
  APPROVE: "approve",
  REJECT: "reject",
}

export const NAV_BARS = {
  HOME: "HOME",
  STUDIO: "STUDIO",
  LESSONS: "LESSONS",
  CALENDAR: "CALENDAR",
  BULLETIN: "BULLETIN",
  BUSINESS: "BUSINESS",
  PRACTICE_SESSIONS: "PRACTICE SESSIONS"
}

export const CAROUSEL_SHOW_MORE = "view more";

export const DAY_OF_WEEK = {
  0: "Sun",
  1: "Mon",
  2: "Tue",
  3: "Wed",
  4: "Thu",
  5: "Fri",
  6: "Sat",
}

export const NOTIFICATION_STATUS = {
  UNREAD: "unread",
  READ: "read",
}

export const NOTIFICATION_EVENT_TYPES = {
  MEMBERSHIP_APPROVED: "membership_approved",
  MEMBERSHIP_REJECTED: "membership_rejected",
  EMPLOYMENT_APPROVED: "employment_approved",
  EMPLOYMENT_REJECTED: "employment_rejected",
  PROPOSAL_CREATED: "proposal_created",
  LESSON_CREATED: "lesson_created",
  LESSON_CANCELLED: "lesson_cancelled",
  MEMBER_REQUEST: "member_request",
}

export const NOTIFICATION_EVENT_TYPES_CAN_VIEW = [
  "lesson_created",
  "proposal_created",
  "member_request",
  "proposal_confirmed",
  "lesson_cancelled"
]

export const NOTIFICATION_EVENT_TYPES_CAN_NOT_VIEW = [
  "membership_rejected",
  "membership_approved",
  "employment_approved",
  "employment_rejected",
]

export const STRIPE_BASE_URL = 'https://connect.stripe.com/oauth/v2/authorize'

export const CALENDAR_ACTION = {
  TODAY: "today",
  PRE: "pre",
  NEXT: "next"
};

export const ACCEPTED_VIDEO_TYPE = [
  "mp4", "mov"
]

export const VIDEO_TIME_FORMAT = "HH:mm";

export const GOOGLE_EVENT = "google-event";
