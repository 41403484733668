import React, { useState, useEffect } from "react";
import {
  AppBar,
  Container,
  IconButton,
  Toolbar,
  Menu,
  MenuItem,
  createGenerateClassName,
  StylesProvider,
  Box,
  withStyles,
  Popper,
  Fade,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  ClickAwayListener,
  createTheme,
  ThemeProvider,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import ReceiptOutlinedIcon from "@material-ui/icons/ReceiptOutlined";
import CalendarTodayOutlinedIcon from "@material-ui/icons/CalendarTodayOutlined";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import SignIn from "../Modal/SignIn";
import ChangePasswordModal from "../Modal/ChangePassword";
import logo from "../../assets/images/logo.svg";
import notification from "../../assets/images/notification.svg";
import calendar from "../../assets/images/calendar.svg";
import check from "../../assets/images/check.svg";
import usersApi from "../../apis/usersApi";
import {
  NOTIFICATION_EVENT_TYPES,
  NOTIFICATION_EVENT_TYPES_CAN_NOT_VIEW,
  NOTIFICATION_EVENT_TYPES_CAN_VIEW,
  NOTIFICATION_STATUS,
  USER_ROLES,
} from "../../utils/constants";
import starImg from "../../assets/images/star.png";
import notificationsApi from "../../apis/notificationsApi";
import BlockIcon from "@material-ui/icons/Block";
import LockIcon from "@material-ui/icons/Lock";
import { formatInTimeZone } from "../../utils/dateTime";
import { isBrowser, isMobile } from "../../utils/common";
import CalendarSyncModal from "../Modal/CalendarSync";

const generateClassName = createGenerateClassName({
  productionPrefix: "c",
  disableGlobal: true,
  seed: "header",
});

const theme = createTheme({
  typography: {
    fontFamily: "'Varela Round', sans-serif",
  },
  overrides: {
    MuiFormLabel: {
      root: {
        "&$focused": {
          color: "#333333",
        },
      },

      focused: {
        color: "#333333",
      },
    },
    MuiInput: {
      underline: {
        "&::after": {
          borderBottom: "2px solid #333333",
        },
      },
    },
  },
});

const HeaderAppBar = withStyles(() => ({
  root: {
    boxShadow: "none !important",
    backgroundColor: "#ffffff",
    padding: "8px 0",
  },
}))(AppBar);

const Nav = withStyles((theme) => ({
  root: {
    marginLeft: "auto",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}))(Box);

const NavMenu = withStyles(() => ({
  root: {
    display: "flex",
  },
}))(Box);

const NavMenuItem = withStyles(() => ({
  root: {
    padding: "0 24px",
    cursor: "pointer",
    color: "#828282",
    display: "flex",
  },
}))(Box);

const UserSettingsPopper = withStyles(() => ({
  root: {
    backgroundColor: "#ffffff",
    borderRadius: 16,
    border: "1px solid #E0E0E0",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.05)",
    width: 210,
    position: "relative",
    left: 16,
  },
}))(Box);

const NotificationPopper = withStyles((theme) => ({
  root: {
    backgroundColor: "#ffffff",
    borderRadius: 16,
    border: "1px solid #E0E0E0",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.05)",
    position: "relative",
    padding: "16px 0",
    width: 300,
    "& li": {
      "&:hover": {
        backgroundColor: "#F7F8F9",
        cursor: "pointer",
      },
      "&:last-child": {
        borderBottom: "1px solid #E4E4E4",
      },
    },
    [theme.breakpoints.down("sm")]: {
      top: "10px",
    },
  },
}))(Box);

const IconMenuMobile = withStyles((theme) => ({
  root: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
}))(IconButton);

const IconUser = withStyles(() => ({
  root: {
    marginRight: 8,
    fontSize: 50,
  },
}))(AccountCircleIcon);

const UserSettingsPopperTriangle = withStyles(() => ({
  root: {
    position: "absolute",
    top: "-7px",
    left: "auto",
    right: "10%",
    width: 12,
    height: 12,
    margin: "auto",
    transform: "rotate(45deg)",
    "-webkit-transform": "rotate(225deg)",
    "-moz-transform": "rotate(45deg)",
    "-o-transform": "rotate(45deg)",
    "-ms-transform": "rotate(45deg)",
    borderRight: "1px solid #E0E0E0",
    borderBottom: "1px solid #E0E0E0",
    backgroundColor: "#ffffff",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.05)",
  },
}))(Box);

const NotificationPopperTriangle = withStyles((theme) => ({
  root: {
    position: "absolute",
    top: "-7px",
    left: "50%",
    width: 12,
    height: 12,
    margin: "auto",
    transform: "translateX(-6px) rotate(45deg)",
    "-webkit-transform": "translateX(-6px) rotate(225deg)",
    "-moz-transform": "translateX(-6px) rotate(45deg)",
    "-o-transform": "translateX(-6px) rotate(45deg)",
    "-ms-transform": "translateX(-6px) rotate(45deg)",
    borderRight: "1px solid #E0E0E0",
    borderBottom: "1px solid #E0E0E0",
    backgroundColor: "#ffffff",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.05)",
    [theme.breakpoints.down("sm")]: {
      left: "59%",
    },
  },
}))(Box);

const UserAvatar = withStyles(() => ({
  root: {
    width: 50,
    height: 50,
    borderRadius: "50%",
    marginRight: 10,
  },
}))(Box);

const StudentTokenHeader = withStyles(() => ({
  root: {},
}))(Box);

const StudentName = withStyles(() => ({
  root: {},
}))(Typography);

const StudentToken = withStyles(() => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
}))(Typography);

const UsedToken = withStyles(() => ({
  root: {
    color: "#007BFF",
    marginLeft: 6,
  },
}))(Typography);

const NotificationWrapper = withStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      marginRight: 50,
    },
  },
}))(Box);

const NotificationBadge = withStyles((theme) => ({
  root: {
    position: "absolute",
    top: "7px",
    right: "-4px",
    width: 10,
    height: 10,
    padding: 4,
    position: "absolute",
    fontSize: "12px",
    color: "#FFFFFF",
    borderRadius: "50%",
    backgroundColor: "#FF0000",
    border: "1px solid #FFFFFF",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      top: "-5px",
    },
  },
}))(Box);

const NotificationPrimaryText = withStyles(() => ({
  root: {
    color: "#1A1A1A",
    fontSize: 16,
    fontWeight: "bold",
    lineHeight: "24px",
    padding: "0 16px",
  },
}))(Typography);

const NotificationSecondaryText = withStyles(() => ({
  root: {
    color: "#5E5E5E",
    fontSize: 14,
    fontWeight: "normal",
    lineHeight: "22px",
    padding: "0 16px",
  },
}))(Typography);

const MobileMenu = withStyles(() => ({
  root: {
    alignItems: "center",
    flexDirection: "row",
    display: "flex",
  },
}))(Box);

const ListNotification = withStyles(() => ({
  root: {
    maxHeight: 280,
    overflow: "auto",
  },
}))(List);

const ListItemNoti = withStyles(() => ({
  root: {
    backgroundColor: ({ unread }) =>
      unread === "true" ? "#F7F8F9" : "#ffffff",
  },
}))(ListItem);

const Header = () => {
  const clientTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [isShowSignInModal, setIsShowSignInModal] = useState(false);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const [popperEl, setPopperEl] = useState(null);
  const [openPopper, setOpenPopper] = useState(false);
  const [notificationEl, setNotificationEl] = useState(null);
  const [openNotification, setOpenNotification] = useState(false);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const currentUser = window.current_user || {};
  const [isOpenChangePasswordModal, setIsOpenChangePasswordModal] =
    useState(false);
  const [isOpenCalendarSyncModal, setIsOpenCalendarSyncModal] = useState(false);
  const message = window.message || {};
  const { alert } = message || {};
  let email = null;
  let role = null;
  const [isNotiError, setIsNotiError] = useState(false);
  
  if (currentUser.id) {
    email = currentUser.email;
    role = currentUser.role;
  }

  let avatar_url = null;
  if (
    currentUser.id &&
    currentUser.avatar_url &&
    currentUser.avatar_url !== ""
  ) {
    avatar_url = currentUser.avatar_url;
  }

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const signOut = async () => {
    localStorage.removeItem("last_notification_id");
    // setOpenPopper(!openPopper);
    await usersApi.signOut();
    window.location = "/";
  };

  const mobileMenuId = "mobile-menu";

  const menuItemLinkStyle = {
    fontSize: 16,
    textTransform: "uppercase",
    color: "#828282",
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
    fontWeight: 500,
  };

  const onOpenPopper = (event) => {
    setPopperEl(event.currentTarget);
    setOpenPopper(!openPopper);
  };

  const goToProfilePage = () => {
    // if (!isBrowser()) {
    //   setMobileMoreAnchorEl(null);
    // } else {
    //   setOpenPopper(!openPopper);
    // }
    if (currentUser.id) {
      window.location = `/users/${currentUser.id}`;
    }
  };

  const goToProductsPage = () => {
    // if (isMobile()) {
    //   setMobileMoreAnchorEl(null);
    // } else {
    //   setOpenPopper(!openPopper);
    // }
    window.location = "/products";
  };

  const goToFeaturesPage = () => {
    window.location = "/features";
  }

  const onCloseNotification = (event) => {
    setNotificationEl(null);
    setOpenNotification(false);
  };

  const onClosePopper = (event) => {
    setPopperEl(null);
    setOpenPopper(false);
  };

  const [notifications, setNotifications] = useState([]);
  const [notificationCountBell, setNotificationCountBell] = useState();
  const [isSeeAllNotifications, setIsSeeAllNotifications] = useState(false);

  const onOpenNotification = (event) => {
    event.preventDefault();
    if (notifications.length > 0) {
      localStorage.setItem("last_notification_id", notifications[0].id);
    }

    setNotificationEl(event.currentTarget);
    setOpenNotification(!openNotification);
    setIsSeeAllNotifications(true);
  };

  const fetchNotificationListFirstLoad = async () => {
    try {
      const isUserLogoutStorage = localStorage.getItem("isUserLogout");

      if (
        !isNotiError &&
        (!isUserLogoutStorage ||
          (isUserLogoutStorage && isUserLogoutStorage === "false"))
      ) {
        localStorage.removeItem("isUserLogout");
        const res = await notificationsApi.getList();
        if (Array.isArray(res) && res.length > 0) {
          setNotifications(res);

          const lastNotificationId = localStorage.getItem(
            "last_notification_id"
          );

          let notificationsUnReadNew = [];

          if (lastNotificationId) {
            const indexLatestNotification = res.findIndex(
              (n) => n.id === lastNotificationId
            );

            if (indexLatestNotification !== -1) {
              notificationsUnReadNew = res.filter((noti, index) => {
                return (
                  noti.attributes.status === NOTIFICATION_STATUS.UNREAD &&
                  index < indexLatestNotification
                );
              });
            }
          } else {
            notificationsUnReadNew = res.filter((noti) => {
              return noti.attributes.status === NOTIFICATION_STATUS.UNREAD;
            });
          }

          if (notificationsUnReadNew.length > 0) {
            setNotificationCountBell(notificationsUnReadNew.length);
          }
        }
        setIsNotiError(false);
      }
    } catch (error) {
      setIsNotiError(true);
    }
  };

  const fetchNotificationList = async () => {
    try {
      const isUserLogoutStorage = localStorage.getItem("isUserLogout");
      if (
        !isNotiError &&
        (!isUserLogoutStorage ||
          (isUserLogoutStorage && isUserLogoutStorage === "false"))
      ) {
        localStorage.removeItem("isUserLogout");
        const res = await notificationsApi.getList();
        if (Array.isArray(res) && res.length > 0) {
          setNotifications(res);

          const lastNotificationId = localStorage.getItem(
            "last_notification_id"
          );

          let notificationsUnReadNew = [];

          if (lastNotificationId) {
            const indexLatestNotification = res.findIndex(
              (n) => n.id === lastNotificationId
            );

            if (indexLatestNotification !== -1) {
              notificationsUnReadNew = res.filter((noti, index) => {
                return (
                  noti.attributes.status === NOTIFICATION_STATUS.UNREAD &&
                  index < indexLatestNotification
                );
              });
            }
          } else {
            notificationsUnReadNew = res.filter((noti) => {
              return noti.attributes.status === NOTIFICATION_STATUS.UNREAD;
            });
          }

          if (notificationsUnReadNew.length > 0) {
            setNotificationCountBell(notificationsUnReadNew.length);

            setIsSeeAllNotifications(false);
          }
        }
        setIsNotiError(false);
      }
    } catch (error) {
      setIsNotiError(true);
    }
  };

  const readNotificationWithIds = async (ids) => {
    try {
      await notificationsApi.update({
        notifications: {
          ids,
        },
      });
    } catch (error) {}
  };

  useEffect(() => {
    if (!isNotiError && currentUser.id && !alert) {
      fetchNotificationListFirstLoad();
    }
  }, [alert, isNotiError]);

  useEffect(() => {
    let interval = null;
    if (!isNotiError && currentUser.id && !alert) {
      interval = setInterval(() => {
        fetchNotificationList();
      }, 50000);
    }

    if (interval) return () => clearInterval(interval);
  }, [alert, isNotiError, currentUser.id]);

  const onClickNotification = async (notification) => {
    const { id, attributes } = notification;
    const { event_type, status, event_url } = attributes;

    if (status === NOTIFICATION_STATUS.UNREAD) {
      setNotifications(
        notifications.map((noti) => {
          if (noti.id === id) {
            return {
              ...noti,
              attributes: {
                ...noti.attributes,
                status: NOTIFICATION_STATUS.READ,
              },
            };
          }
          return noti;
        })
      );

      await readNotificationWithIds([id]);
    }

    if (NOTIFICATION_EVENT_TYPES_CAN_VIEW.includes(event_type)) {
      if (event_type === NOTIFICATION_EVENT_TYPES.PROPOSAL_CREATED) {
        const proposedTimeId = event_url.split("lessons/")[1];
        const url = `/lessons?status[]=scheduled&proposed_time_id=${proposedTimeId}`;
        window.location.href = url;
      } else {
        window.location.href = event_url;
      }
    }

    setNotificationEl(null);
    setOpenNotification(false);
  };

  const renderEventType = (eventType) => {
    switch (eventType) {
      case NOTIFICATION_EVENT_TYPES.PROPOSAL_CREATED:
        return <img src={calendar} />;
      case NOTIFICATION_EVENT_TYPES.MEMBERSHIP_REJECTED:
      case NOTIFICATION_EVENT_TYPES.EMPLOYMENT_REJECTED:
      case NOTIFICATION_EVENT_TYPES.LESSON_CANCELLED:
        return <BlockIcon style={{ color: "#FF0000" }} />;

      default:
        return <img src={check} />;
    }
  };

  const userLogin = () => {
    window.location = "/users/login"
  }

  return (
    <>
      <StylesProvider generateClassName={generateClassName}>
        <ThemeProvider theme={theme}>
          <Box
            style={{
              borderBottom: "1px solid #E0E0E0",
            }}
          >
            <Container>
              <HeaderAppBar position="static" color={"default"}>
                <Toolbar style={{ justifyContent: "space-between" }}>
                  <a href="/" style={{ textDecoration: "none" }}>
                    <h1
                      style={{
                        fontSize: 36,
                        fontWeight: 400,
                        lineHeight: "52px",
                        color: "#000000",
                        textTransform: "uppercase",
                        margin: 0,
                      }}
                    >
                      Swyvel
                    </h1>
                  </a>
                  <Nav>
                    {currentUser.id ? (
                      <NavMenu>
                        <NavMenuItem>
                          <NotificationWrapper onClick={onOpenNotification}>
                            <img src={notification} alt="" />
                            {notificationCountBell &&
                              !isSeeAllNotifications && (
                                <NotificationBadge>
                                  {notificationCountBell}
                                </NotificationBadge>
                              )}
                          </NotificationWrapper>
                        </NavMenuItem>
                        <NavMenuItem>
                          <a
                            style={{
                              ...menuItemLinkStyle,
                              textTransform: "inherit",
                              color: "#000000",
                            }}
                            onClick={onOpenPopper}
                          >
                            {avatar_url ? (
                              <UserAvatar>
                                <img
                                  src={avatar_url}
                                  alt=""
                                  style={{
                                    width: "100%",
                                    borderRadius: "50%",
                                    height: "100%",
                                    objectFit: "cover",
                                  }}
                                />
                              </UserAvatar>
                            ) : (
                              <IconUser />
                            )}
                            {currentUser.id && (
                              <>
                                {currentUser.role === USER_ROLES.STUDENT ? (
                                  <StudentTokenHeader>
                                    <StudentName>
                                      {`${currentUser.first_name}`}
                                    </StudentName>
                                    <StudentToken>
                                      <img src={starImg} alt="" />
                                      <UsedToken>
                                        {currentUser.total_tokens
                                          ? currentUser.total_tokens -
                                            currentUser.used_tokens
                                          : 0}
                                      </UsedToken>
                                      {currentUser.total_tokens > 0 && (
                                        <>{`/${currentUser.total_tokens}`}</>
                                      )}
                                    </StudentToken>
                                  </StudentTokenHeader>
                                ) : (
                                  <span>{`${currentUser.first_name}`}</span>
                                )}
                              </>
                            )}
                            <ExpandMoreIcon style={{ marginLeft: 8 }} />
                          </a>
                        </NavMenuItem>
                      </NavMenu>
                    ) : (
                      <NavMenu>
                        <NavMenuItem>
                          <a
                            onClick={goToFeaturesPage}
                            style={menuItemLinkStyle}
                          >
                            features
                          </a>
                        </NavMenuItem>

                        <NavMenuItem>
                          <a
                            onClick={userLogin}
                            style={menuItemLinkStyle}
                          >
                            Sign in/Sign up
                          </a>
                        </NavMenuItem>
                      </NavMenu>
                    )}
                  </Nav>
                  {isMobile() && (
                    <MobileMenu>
                      {currentUser.id && (
                        <NotificationWrapper onClick={onOpenNotification}>
                          <img src={notification} alt="" />
                          {notificationCountBell && !isSeeAllNotifications && (
                            <NotificationBadge>
                              {notificationCountBell}
                            </NotificationBadge>
                          )}
                        </NotificationWrapper>
                      )}

                      <IconMenuMobile
                        edge="end"
                        color="inherit"
                        aria-label="menu"
                        onClick={handleMobileMenuOpen}
                        aria-controls={mobileMenuId}
                        aria-haspopup="true"
                      >
                        <MenuIcon />
                      </IconMenuMobile>
                    </MobileMenu>
                  )}
                </Toolbar>
              </HeaderAppBar>
            </Container>
          </Box>

          {isShowSignInModal && (
            <SignIn
              isOpen={isShowSignInModal}
              onClose={() => setIsShowSignInModal(false)}
            />
          )}

          {isOpenChangePasswordModal && (
            <ChangePasswordModal
              isOpen={isOpenChangePasswordModal}
              onClose={() => setIsOpenChangePasswordModal(false)}
              email={email}
            />
          )}

          {isOpenCalendarSyncModal && (
            <CalendarSyncModal
              isOpen={isOpenCalendarSyncModal}
              onClose={() => setIsOpenCalendarSyncModal(false)}
              currentUser={currentUser}
              email={email}
            />
          )}

          {isMobile() && (
            <>
              {currentUser.id ? (
                <Menu
                  anchorEl={mobileMoreAnchorEl}
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                  id={mobileMenuId}
                  keepMounted
                  transformOrigin={{ vertical: "top", horizontal: "right" }}
                  open={isMobileMenuOpen}
                  onClose={handleMobileMenuClose}
                >
                  {currentUser.id && currentUser.role === USER_ROLES.STUDENT && (
                    <MenuItem>
                      <ListItemIcon
                        style={{
                          minWidth: 35,
                        }}
                      >
                        <img src={starImg} alt="" />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <>
                            <StudentToken>
                              <UsedToken>
                                {currentUser.total_tokens
                                  ? currentUser.total_tokens -
                                    currentUser.used_tokens
                                  : 0}
                              </UsedToken>
                              {currentUser.total_tokens > 0 && (
                                <>{`/${currentUser.total_tokens}`}</>
                              )}
                            </StudentToken>
                          </>
                        }
                      />
                    </MenuItem>
                  )}

                  <MenuItem onClick={goToProfilePage}>
                    <ListItemIcon
                      style={{
                        minWidth: 35,
                      }}
                    >
                      <PersonOutlineIcon />
                    </ListItemIcon>
                    <ListItemText primary="Profile" />
                  </MenuItem>
                  { currentUser.is_granted_scope && (
                    <MenuItem
                      onClick={() => {
                        setIsOpenCalendarSyncModal(true);
                        handleMobileMenuClose();
                      }}
                    >
                      <ListItemIcon
                        style={{
                          minWidth: 35,
                        }}
                      >
                        <CalendarTodayOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText primary="Calendar Sync" />
                    </MenuItem>
                  )}
                 
                  {role &&
                    (role === USER_ROLES.STUDENT ||
                      role === USER_ROLES.STUDIO) && (
                      <MenuItem onClick={goToProductsPage}>
                        <ListItemIcon
                          style={{
                            minWidth: 35,
                          }}
                        >
                          <ReceiptOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText primary="Membership" />
                      </MenuItem>
                    )}
                  <MenuItem
                    onClick={() => {
                      setIsOpenChangePasswordModal(true);
                      handleMobileMenuClose();
                    }}
                  >
                    <ListItemIcon
                      style={{
                        minWidth: 35,
                      }}
                    >
                      <LockIcon />
                    </ListItemIcon>
                    <ListItemText primary="Change Password" />
                  </MenuItem>
                  <MenuItem onClick={signOut}>
                    <ListItemIcon
                      style={{
                        minWidth: 35,
                        color: "#FF0000",
                      }}
                    >
                      <ExitToAppIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary="Sign out"
                      style={{ color: "#FF0000" }}
                    />
                  </MenuItem>
                </Menu>
              ) : (
                <Menu
                  anchorEl={mobileMoreAnchorEl}
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                  id={mobileMenuId}
                  keepMounted
                  transformOrigin={{ vertical: "top", horizontal: "right" }}
                  open={isMobileMenuOpen}
                  onClose={handleMobileMenuClose}
                >
                  <MenuItem
                    onClick={userLogin}
                  >
                    Sign in/Sign up
                  </MenuItem>
                  <MenuItem
                    onClick={goToFeaturesPage}
                  >
                    Features
                  </MenuItem>
                </Menu>
              )}
            </>
          )}
          {isBrowser && openPopper && (
            <Popper
              open={openPopper}
              anchorEl={popperEl}
              placement={"bottom-end"}
              transition
            >
              {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                  <ClickAwayListener onClickAway={onClosePopper}>
                    <UserSettingsPopper>
                      <List component="nav" aria-label="main mailbox folders">
                        <ListItem button onClick={goToProfilePage}>
                          <ListItemIcon
                            style={{
                              minWidth: 35,
                            }}
                          >
                            <PersonOutlineIcon />
                          </ListItemIcon>
                          <ListItemText primary="Profile" />
                        </ListItem>
                        { currentUser.is_granted_scope && (
                          <ListItem
                            button
                            onClick={() => {
                              setIsOpenCalendarSyncModal(true);
                              setOpenPopper(false);
                            }}
                          >
                            <ListItemIcon
                              style={{
                                minWidth: 35,
                              }}
                            >
                              <CalendarTodayOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText primary="Calendar Sync" />
                          </ListItem>
                        )}
                        {role &&
                          (role === USER_ROLES.STUDENT ||
                            role === USER_ROLES.STUDIO) && (
                            <ListItem button onClick={goToProductsPage}>
                              <ListItemIcon
                                style={{
                                  minWidth: 35,
                                }}
                              >
                                <ReceiptOutlinedIcon />
                              </ListItemIcon>
                              <ListItemText primary="Membership" />
                            </ListItem>
                          )}
                        <ListItem
                          button
                          onClick={() => {
                            setIsOpenChangePasswordModal(true);
                            setOpenPopper(false);
                          }}
                        >
                          <ListItemIcon
                            style={{
                              minWidth: 35,
                            }}
                          >
                            <LockIcon />
                          </ListItemIcon>
                          <ListItemText primary="Change Password" />
                        </ListItem>
                        <ListItem button onClick={signOut}>
                          <ListItemIcon
                            style={{
                              minWidth: 35,
                              color: "#FF0000",
                            }}
                          >
                            <ExitToAppIcon />
                          </ListItemIcon>
                          <ListItemText
                            primary="Sign out"
                            style={{ color: "#FF0000" }}
                          />
                        </ListItem>
                      </List>
                      <UserSettingsPopperTriangle />
                    </UserSettingsPopper>
                  </ClickAwayListener>
                </Fade>
              )}
            </Popper>
          )}

          {openNotification && (
            <Popper
              open={openNotification}
              anchorEl={notificationEl}
              placement={"bottom"}
              transition
              style={{
                zIndex: 2,
              }}
            >
              {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                  <ClickAwayListener onClickAway={onCloseNotification}>
                    <NotificationPopper>
                      <NotificationPrimaryText>
                        Notifications
                      </NotificationPrimaryText>

                      {notifications.length > 0 && (
                        <NotificationSecondaryText>
                          You have{" "}
                          {
                            notifications.filter(
                              (e) =>
                                e.attributes.status ===
                                NOTIFICATION_STATUS.UNREAD
                            ).length
                          }{" "}
                          unread messages
                        </NotificationSecondaryText>
                      )}

                      <ListNotification>
                        {notifications.map((notification, index) => {
                          const { event_content, send_at, status, event_type } =
                            notification.attributes;

                          return (
                            <ListItemNoti
                              onClick={() => onClickNotification(notification)}
                              key={`notification-${index}`}
                              unread={(
                                status === NOTIFICATION_STATUS.UNREAD
                              ).toString()}
                              style={{
                                cursor:
                                  NOTIFICATION_EVENT_TYPES_CAN_NOT_VIEW.includes(
                                    event_type
                                  )
                                    ? "default"
                                    : "pointer",
                              }}
                            >
                              <ListItemIcon
                                style={{
                                  minWidth: 35,
                                }}
                              >
                                {renderEventType(event_type)}
                              </ListItemIcon>
                              <ListItemText
                                primary={event_content}
                                secondary={formatInTimeZone(
                                  send_at,
                                  "dd MMM, yyyy",
                                  clientTimeZone
                                )}
                              />
                            </ListItemNoti>
                          );
                        })}
                      </ListNotification>
                      <NotificationPopperTriangle />
                    </NotificationPopper>
                  </ClickAwayListener>
                </Fade>
              )}
            </Popper>
          )}
        </ThemeProvider>
      </StylesProvider>
    </>
  );
};

Header.defaultProps = {};

Header.propTypes = {};

export default Header;
