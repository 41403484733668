import { BASE_URL } from "../utils/constants";
import axiosClient from "./apiClient";

const notificationsApi = {
  getList: async () => {
    try {
      const url = `${BASE_URL}/notifications`;
      const response = await axiosClient.get(url);
      return response.data;
    } catch (error) {
      throw error.data;
    }
  },
  update: async (params) => {
    try {
      const url = `${BASE_URL}/notifications/mark_as_read`;
      const response = await axiosClient.post(url, params);
      return response.data;
    } catch (error) {
      throw error.data;
    }
  },
};

export default notificationsApi;