import React, { useState } from "react";
import {
  Backdrop,
  Box,
  Fade,
  Modal,
  withStyles,
  createGenerateClassName,
  createTheme,
  StylesProvider,
  ThemeProvider,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Input,
  FormHelperText,
  InputAdornment,
  IconButton,
  CircularProgress,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import usersApi from "../../../apis/usersApi";
import imageSuccess from "../../../assets/images/sign-up-success.svg";

const generateClassName = createGenerateClassName({
  productionPrefix: "c",
  disableGlobal: true,
  seed: "change-password-modal",
});

const theme = createTheme({
  typography: {
    fontFamily: "'Varela Round', sans-serif",
  },
  overrides: {
    MuiFormLabel: {
      root: {
        "&$focused": {
          color: "#333333",
        },
      },

      focused: {
        color: "#333333",
      },
    },
    MuiInput: {
      underline: {
        "&::after": {
          borderBottom: "2px solid #333333",
        },
      },
    },
  },
});

const ModalBox = withStyles((theme) => ({
  root: {
    backgroundColor: "#ffffff",
    padding: 32,
    borderRadius: 16,
    position: "relative",
    maxWidth: 500,
    "&:focus-visible": {
      outline: "none",
    },
    [theme.breakpoints.down("sm")]: {
      padding: 16,
      margin: 0,
      maxWidth: "100%",
      marginTop: "auto",
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
  },
}))(Box);

const Close = withStyles(() => ({
  root: {
    position: "absolute",
    right: 12,
    top: 12,
    cursor: "pointer",
  },
}))(Box);

const ModalHead = withStyles((theme) => ({
  root: {
    fontSize: 20,
    lineHeight: "28px",
    fontWeight: 500,
    margin: 0,
    marginBottom: 32,
    textTransform: "uppercase",
    [theme.breakpoints.down("sm")]: {
      marginTop: 16,
    },
  },
}))(Typography);

const ChangePasswordButton = withStyles((theme) => ({
  root: {
    color: "#ffffff",
    backgroundColor: "#000000",
    "&:hover": {
      backgroundColor: "#000000",
    },
    height: 50,
    borderRadius: "140px",
    marginTop: 24,
  },
}))(Button);

const SuccessText = withStyles((theme) => ({
  root: {
    fontSize: 20,
    fontWeight: 400,
    margin: 0,
    textTransform: "uppercase",
    marginBottom: 8,
  },
}))(Typography);

const ThanksText = withStyles((theme) => ({
  root: {
    fontSize: 14,
    fontWeight: 400,
    margin: 0,
    marginBottom: 16,
    color: "#4F4F4F",
  },
}))(Typography);

const ImageSuccess = withStyles(() => ({
  root: {
    textAlign: "center",
    marginBottom: 30,
    minHeight: 160,
  },
}))(Box);

const ChangePasswordModal = ({ isOpen, onClose, email }) => {
  const [formData, setFormData] = useState({
    email: email,
    current_password: "",
    password: "",
    password_confirmation: "",
    showCurrentPassword: false,
    showNewPassword: false,
    showNewPasswordConfirmation: false,
  });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errorMessages, setErrorMessages] = useState({});
  const [isChangedPasswordSuccess, setIsChangedPasswordSuccess] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (prop) => (event) => {
    setErrorMessages({});
    setFormData({ ...formData, [prop]: event.target.value });
  };

  const onShowCurrentPassword = () => {
    setFormData({
      ...formData,
      showCurrentPassword: !formData.showCurrentPassword,
    });
  };

  const onShowNewPassword = () => {
    setFormData({ ...formData, showNewPassword: !formData.showNewPassword });
  };

  const onShowNewPasswordConfirmation = () => {
    setFormData({
      ...formData,
      showNewPasswordConfirmation: !formData.showNewPasswordConfirmation,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onChangePasswordHandler = async () => {
    try {
      setIsSubmitted(true);
      const { email, current_password, password, password_confirmation } =
        formData;
      if (
        current_password === "" ||
        password === "" ||
        password_confirmation === ""
      )
        return;

      if (current_password !== "" && current_password.trim().length === 0) {
        setErrorMessages({
          current_password: "current_password format is not correct.",
        });
        return;
      }

      if (password !== "" && password.trim().length === 0) {
        setErrorMessages({
          password: "password format is not correct.",
        });
        return;
      }

      if (
        password_confirmation !== "" &&
        password_confirmation.trim().length === 0
      ) {
        setErrorMessages({
          password_confirmation: "password_confirmation format is not correct.",
        });
        return;
      }

      setIsLoading(true);
      const data = {
        user: {
          email: email,
          current_password: current_password,
          password: password,
          password_confirmation: password_confirmation,
        },
      };
      await usersApi.changePassword(data);
      setIsChangedPasswordSuccess(true);
      setIsSubmitted(false);
      setIsLoading(false);

      localStorage.removeItem("last_notification_id");
      localStorage.setItem("isUserLogout", "true");

      setTimeout(() => {
        window.location = "/";
      }, 3000);
    } catch (error) {
      setErrorMessages(error);
      setIsLoading(false);
    }
  };

  return (
    <StylesProvider generateClassName={generateClassName}>
      <ThemeProvider theme={theme}>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={isOpen}
          onClose={onClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Fade in={isOpen}>
            <ModalBox>
              <Close onClick={onClose}>
                <CloseIcon />
              </Close>
              <ModalHead>Change password</ModalHead>
              {!isChangedPasswordSuccess ? (
                <>
                  <FormControl
                    fullWidth
                    error={
                      (isSubmitted && formData.current_password === "") ||
                      Boolean(errorMessages.current_password)
                    }
                    style={{
                      marginBottom: 16,
                    }}
                  >
                    <InputLabel htmlFor="current_password">
                      Old Password
                    </InputLabel>
                    <Input
                      id="current_password"
                      type={formData.showCurrentPassword ? "text" : "password"}
                      value={formData.current_password}
                      onChange={handleChange("current_password")}
                      aria-describedby="current-password-error-text"
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={onShowCurrentPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {formData.showCurrentPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    {isSubmitted && formData.current_password === "" && (
                      <FormHelperText id="current-password-error-text">
                        Please enter your old password.
                      </FormHelperText>
                    )}
                    {isSubmitted && Boolean(errorMessages.current_password) && (
                      <FormHelperText id="current-password-error-text">
                        {errorMessages.current_password.replace(
                          "current_password",
                          "Old password"
                        )}
                      </FormHelperText>
                    )}
                  </FormControl>
                  <FormControl
                    fullWidth
                    error={
                      (isSubmitted && formData.password === "") ||
                      Boolean(errorMessages.password)
                    }
                    style={{
                      marginBottom: 16,
                    }}
                  >
                    <InputLabel htmlFor="password">New Password</InputLabel>
                    <Input
                      id="password"
                      type={formData.showNewPassword ? "text" : "password"}
                      value={formData.password}
                      onChange={handleChange("password")}
                      aria-describedby="password-error-text"
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle new password visibility"
                            onClick={onShowNewPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {formData.showNewPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    {isSubmitted && formData.password === "" && (
                      <FormHelperText id="password-error-text">
                        Please enter your new password.
                      </FormHelperText>
                    )}
                    {isSubmitted && Boolean(errorMessages.password) && (
                      <FormHelperText id="password-error-text">
                        {errorMessages.password.replace(
                          "password",
                          "New password"
                        )}
                      </FormHelperText>
                    )}
                  </FormControl>
                  <FormControl
                    fullWidth
                    error={
                      (isSubmitted && formData.password_confirmation === "") ||
                      Boolean(errorMessages.password_confirmation)
                    }
                    style={{
                      marginBottom: 16,
                    }}
                  >
                    <InputLabel htmlFor="password_confirmation">
                      Confirm New Password
                    </InputLabel>
                    <Input
                      id="password_confirmation"
                      type={
                        formData.showNewPasswordConfirmation
                          ? "text"
                          : "password"
                      }
                      value={formData.password_confirmation}
                      onChange={handleChange("password_confirmation")}
                      aria-describedby="password_confirmation-error-text"
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle confirm new password visibility"
                            onClick={onShowNewPasswordConfirmation}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {formData.showNewPasswordConfirmation ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    {isSubmitted && formData.password_confirmation === "" && (
                      <FormHelperText id="password_confirmation-error-text">
                        Please enter your confirm new password.
                      </FormHelperText>
                    )}
                    {isSubmitted &&
                      Boolean(errorMessages.password_confirmation) && (
                        <FormHelperText id="password_confirmation-error-text">
                          {errorMessages.password_confirmation.replace(
                            "password_confirmation",
                            "Confirm new password"
                          )}
                        </FormHelperText>
                      )}
                  </FormControl>
                  <FormControl fullWidth>
                    <ChangePasswordButton
                      variant="contained"
                      color="primary"
                      onClick={onChangePasswordHandler}
                    >
                      {!isLoading ? (
                        "Update"
                      ) : (
                        <CircularProgress
                          disableShrink
                          style={{ color: "#fff" }}
                        />
                      )}
                    </ChangePasswordButton>
                  </FormControl>
                </>
              ) : (
                <>
                  <SuccessText>Success!</SuccessText>
                  <ThanksText>
                    Thanks, you need sign in again after 3s.
                  </ThanksText>
                  <ImageSuccess>
                    <img src={imageSuccess} alt="" />
                  </ImageSuccess>
                  <FormControl fullWidth>
                    <ChangePasswordButton
                      variant="contained"
                      color="primary"
                      onClick={onClose}
                    >
                      OK
                    </ChangePasswordButton>
                  </FormControl>
                </>
              )}
            </ModalBox>
          </Fade>
        </Modal>
      </ThemeProvider>
    </StylesProvider>
  );
};

export default ChangePasswordModal;
