import axios from 'axios';
import queryString from 'query-string';
import { API_PREFIX, BASE_URL, VERSION } from '../utils/constants';

const csrfToken = document.querySelector('[name=csrf-token]');

const axiosClient = axios.create({
  baseURL: `${BASE_URL}/${API_PREFIX}/${VERSION}`,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'X-CSRF-TOKEN': csrfToken.content,
    'ClientTimeZone': Intl.DateTimeFormat().resolvedOptions().timeZone,
  },
  paramsSerializer: params => queryString.stringify(params),
});

axiosClient.interceptors.request.use(async (config) => {
  // Handle token here ...
  return config;
});

axiosClient.interceptors.response.use(async (response) => {
  if (response.data) {
    return response.data;
  }

  return response;
}, (error) => {
  // Handle errors
  if (error.response) {
    throw error.response;
  }

  return error;
});

export default axiosClient;