import { parseISO, getDay } from "date-fns";
import { format, utcToZonedTime } from "date-fns-tz";
import { DAY_OF_WEEK } from "./constants";

export const formatInTimeZone = (day, fmt, tz) =>
  format(utcToZonedTime(parseISO(day), tz),
    fmt,
    { timeZone: tz });

export const formatDateTime = (day, fmt) =>
  format(parseISO(day), fmt);

export const getDayOfWeek = (day) => {
  return DAY_OF_WEEK[getDay(parseISO(day))];
}
